.text, .text p {
  font-size: 16px;
  line-height: 26px;
  margin: 36px 2.5rem;

  & p {
    margin: 12px 0;
  }
}

.center p {
  text-align: center;
}