@import 'styles/global';

.container {
  box-sizing: border-box;
  padding: 3rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .TopRow {
    box-sizing: border-box;
    margin: 0 3rem 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 15%;
    .Text {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .MidSection {
    margin: 3rem 0;
    height: 60%;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    .FAQContainer {
      box-sizing: border-box;
      flex: 1;
      height: 100%;
      color: $primary-black;
      font-size: 2rem;
      text-align: left;
      overflow-y: auto;
      @include custom-scrollbar;
      .Header {
        margin: 1rem 0 1rem 0;
        font-size: 2.5rem;
        &:first-of-type {
          margin: 0 0 1rem 0;
        }
      }
      p, ul, ol, li {
        font-size: 2rem;
        margin-bottom: 1.5rem;
      }
    }
    &:after {
      display: block;
      content: '';
      width: 14rem;
      height: 100%;
      right: 0;
      background-image: url("./monsters.svg");
      background-size: 90%;
      background-position: bottom right;
      background-repeat: no-repeat;
    }
  }

  .bottomRow {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    .buttonMoveToGame {
      margin: 0 1rem 0;
      box-shadow: 0 4px 9px rgba(0, 79, 196, 0.2);
    }

    .buttonPractice {
      margin: 0 1rem 0;
      background-color: $primary-dark-blue;
      color: $primary-white;
      box-shadow: 0 4px 9px rgba(0, 79, 196, 0.25);
    }
  }
}



