@import "../../styles/global";

.button {
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  box-shadow: none;
  color: black;
  font-weight: 600;
  letter-spacing: 0.02em;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  padding: 1.5rem 2.25rem 1.5rem 7.35rem;
  margin: 0;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 2.1rem;
  line-height: 3.3rem;
  text-align: center;
  transition: all 250ms ease-in-out,
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  outline: none;

  @media (orientation: portrait) {
    font-size: 3rem;
    padding: 2rem 2.75rem 2rem 7.85rem;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 4px 9px rgba(0, 79, 196, 0.2);
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &.default {
    color: $primary-dark-blue;
    padding: 1.5rem 4.5rem;

    @media (orientation: portrait) {
      padding: 2rem 5rem;
    }
  }

  &.primary {
    color: $primary-white;
    background-color: $primary-dark-blue;
    padding: 1.5rem 4.5rem;
  }

  &.submit, &.play {
    border: 1px solid $primary-green;
    background: url("~assets/images/check.svg") no-repeat 2.25rem center;
    background-size: 25%;

    &:hover {
      box-shadow: 0 4px 9px rgba(5, 166, 96, 0.2);
    }
  }

  &.play {
    background-image: url("~assets/images/play-green.svg");
  }

  &.cancel {
    border: 1px solid $primary-red;
    background: url("~assets/images/cancel-red.svg") no-repeat 2.25rem center;
    background-size: 25%;

    &:hover {
      box-shadow: 0 4px 9px rgba(229, 53, 53, 0.2);
    }
  }

  &.stop, &.quit {
    border: 1px solid $primary-orange;
    background: url("~assets/images/pause-orange.svg") no-repeat 2.25rem center;
    background-size: 25%;

    &:hover {
      box-shadow: 0 4px 9px rgba(229, 122, 0, 0.2);
    }
  }

  &.quit {
    background-image: url("~assets/images/quit-orange.svg");
  }

  &.close, &.logout, &.assessment, &.fullscreen {
    background: url("~assets/images/x.svg") no-repeat center;
    padding: 0;
    margin: 0 0 0 auto;
    background-size: 2.7rem;
    width: 5.4rem;
    height: 5.4rem;
    border-radius: 10px;
  }

  &.logout {
    width: 15rem;
    font-size: 1.5rem;
    background-image: url("~assets/images/logout.svg");
    background-position: 90%;
    padding-left: 2rem;
    text-align: left;
  }


  &.fullscreen {
    width: 15rem;
    font-size: 1.5rem;
    background-image: none;
    background-position: 90%;
  }

  &.assessment {
    background-image: url("~assets/images/assessment.svg");
  }
}
