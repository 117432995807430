@import "styles/global";

.card {
  border: 1px solid $secondary-gray-4;
  background: $primary-white;
  border-radius: 5px;
  display: inline-grid;
  align-content: start;
  row-gap: 12px;
  padding: 24px;
  text-align: left;

  &[dir="rtl"] {
    text-align: right;
  }

  grid-template-areas:
    "strength"
    "icon"
    "name"
    "description"
    "examples";

  width: 75rem;
  margin: 1.25rem 2.5rem;

  @include for-desktop {
    width: 24rem;
  }
}

.icon {
  grid-area: icon;
  width: 48px;
  height: 48px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  &.realistic {
    background-image: url("../icons/riasec-r.svg");
  }
  &.investigative {
    background-image: url("../icons/riasec-i.svg");
  }
  &.artistic {
    background-image: url("../icons/riasec-a.svg");
  }
  &.social {
    background-image: url("../icons/riasec-s.svg");
  }
  &.enterprising {
    background-image: url("../icons/riasec-e.svg");
  }
  &.conventional {
    background-image: url("../icons/riasec-c.svg");
  }
}

.name {
  grid-area: name;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
}

.percentage {
  grid-area: percentage;
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  display: none;
}

.strength {
  grid-area: strength;
  height: 36px;
}

.strengthTag {
  &.low {
    background-color: $primary-red;
  }
  &.medium {
    background-color: $primary-orange;
  }
  &.strongest {
    background-color: $primary-green;
  }
}

.description {
  grid-area: description;
  font-size: 14px;
  line-height: 24px;
}

.examples {
  grid-area: examples;
  font-size: 14px;
  line-height: 24px;
  font-style: italic;
}

.learnMore {
  grid-area: learn-more;
}
